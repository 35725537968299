import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import courseRoutes from './courses';
import templateTasksRoutes from './templates-tasks';
import templateCourseRoutes from './templates-courses';
import templateMeasuresRoutes from './templates-measures';
import templateLessonsRoutes from './templates-lessons';
import templateProgramsRoutes from './templates-programs';
import exercisesRoutes from './exercises';
import programsRoutes from './programs';
import { nextTick } from 'vue';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue'),
    meta: {
      title: 'Coachplan',
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {
      title: 'Coachplan - Opret profil',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Signup.vue')
  },
  {
    path: '/programs/print/:programId',
    name: 'PrintProgram',
    meta: {
      title: 'Print program',
      requiresAuth: true
    },
    components: {
      default: () => import(/* webpackChunkName: "print-program" */ '../views/Programs/Print/Index.vue')
    }
  },
  {
    path: '/clients/:clientId/journal/print',
    name: 'printJournal',
    meta: {
      title: 'Print Journal',
      requiresAuth: true
    },
    components: {
      default: () => import(/* webpackChunkName: "print-journal" */ '../views/Clients/View/PrintJournal/Index.vue')
    }
  },
  {
    path: '/choose-subscription',
    name: 'Vælg abonnement',
    meta: {
      title: 'Vælg abonnement',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "subscription" */ '../views/PageSelectSubscription.vue'),
    children: [
      {
        path: '/select-subscription',
        name: 'SubscriptionIndex',
        meta: {
          title: 'Abonnement',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription/PageSubscription.vue')
      }
    ]
  },
  {
    path: '/dashboard-index',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardLayout.vue'),
    meta: {
      title: 'Kontrolpanel',
      requiresAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'DashboardIndex',
        meta: {
          title: 'Kontrolpanel',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Index.vue')
      },
      {
        path: '/templates',
        name: 'TemplatesIndex',
        meta: {
          title: 'Templates',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "templates" */ '../views/Templates/Index.vue'),
        children: [
          {
            path: '',
            name: 'TemplatesIndexHome',
            component: () => import(/* webpackChunkName: "templates" */ '../views/Templates/TemplatesIndex.vue')
          },
          ...templateTasksRoutes,
          ...templateCourseRoutes,
          ...templateMeasuresRoutes,
          ...templateProgramsRoutes,
          ...templateLessonsRoutes,
          {
            path: '/templates/journals',
            name: 'journalTemplates',
            meta: {
              title: 'Journal templates'
            },
            components: {
              default: () => import(/* webpackChunkName: "journal-templates" */ '../views/Templates/Journals/Index.vue')
            },
            children: [
              {
                path: '',
                name: 'journalTemplatesIndex',
                meta: {
                  title: 'Journal templates'
                },
                components: {
                  default: () => import(/* webpackChunkName: "journal-templates" */ '../views/Templates/Journals/PageJournals.vue')
                }
              }
            ]
          },
          {
            path: '/templates/surveys',
            name: 'surveys',
            meta: {
              title: 'Spørgeskemaer',
              requiresAuth: true
            },
            components: {
              default: () => import(/* webpackChunkName: "surveys" */ '../views/Templates/Surveys/Index.vue')
            },
            children: [
              {
                path: '',
                name: 'list-surveys',
                meta: {
                  title: 'Spørgeskemaer',
                  requiresAuth: true
                },
                components: {
                  default: () => import(/* webpackChunkName: "surveys" */ '../views/Templates/Surveys/PageSurveys.vue')
                }
              },
              {
                path: 'create-survey',
                name: 'create-survey',
                components: {
                  default: () => import(/* webpackChunkName: "surveys" */ '../views/Templates/Surveys/Create/Index.vue')
                }
              }
            ]
          }
        ]
      },
      ...courseRoutes,
      ...exercisesRoutes,
      ...programsRoutes,
      {
        path: '/clients',
        name: 'Clients',
        meta: {
          title: 'Klienter',
          requiresAuth: true
        },
        components: {
          default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/Index.vue')
        }
      },
      {
        path: '/messages',
        name: 'Messages',
        meta: {
          title: 'Beskeder',
          requiresAuth: true
        },
        components: {
          default: () => import(/* webpackChunkName: "messages" */ '../views/Messages/Index.vue')
        },
        children: [
          {
            path: '',
            name: 'ViewMessages',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Messages/PageMessages.vue')
            }
          }
        ]
      },
      {
        path: '/notifications',
        name: 'notifications',
        meta: {
          title: 'Notifikationer',
          requiresAuth: true
        },
        components: {
          default: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications/Index.vue')
        },
        children: [
          {
            path: '',
            name: 'view-notifications',
            meta: {
              title: 'Notifikationer',
              requiresAuth: true
            },
            components: {
              default: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications/PageNotifications.vue')
            }
          }
        ]
      },
      {
        path: '/clients/:id',
        name: 'ViewClient',
        components: {
          default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClient.vue')
        },
        children: [
          {
            path: '',
            name: 'ViewClientInfo',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientInfo.vue')
            }
          },
          {
            path: 'programs',
            name: 'ViewClientPrograms',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientPrograms.vue')
            }
          },
          {
            path: 'courses',
            name: 'ViewClientCourses',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientCourse.vue')
            }
          },
          {
            path: 'tasks',
            name: 'ViewClientTasks',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientEvents.vue')
            }
          },
          {
            path: 'surveys',
            name: 'ViewClientSurveys',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientSurveys.vue')
            }
          },
          {
            path: 'journal',
            name: 'ViewClientJournal',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientJournal.vue')
            }
          },
          {
            path: 'images',
            name: 'ViewClientImages',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientImages.vue')
            }
          },
          {
            path: 'measures',
            name: 'ViewClientMeasures',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientMeasures.vue')
            }
          },
          {
            path: 'planning',
            name: 'ViewClientPlanning',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientPlanning.vue')
            }
          },
          {
            path: 'settings',
            name: 'view-client-settings',
            components: {
              default: () => import(/* webpackChunkName: "clients" */ '../views/Clients/View/PageClientSettings.vue')
            }
          }
        ]
      },
      {
        path: '/support',
        name: 'Support',
        meta: {
          title: 'Support',
          requiresAuth: true
        },
        components: {
          default: () => import(/* webpackChunkName: "messages" */ '../views/Support/Index.vue')
        }
      },
      {
        path: '/account',
        name: 'Account',
        meta: {
          title: 'Konto',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Index.vue'),
        children: [
          {
            path: 'profile',
            meta: {
              title: 'Profil'
            },
            name: 'AccountIndex',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageAccountProfile.vue')
          },
          {
            path: 'change-password',
            meta: {
              title: 'Skift password'
            },
            name: 'AccountChangePassword',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageAccountChangePassword.vue')
          },
          {
            path: 'users',
            meta: {
              title: 'Brugere'
            },
            name: 'AccountUsers',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageUsers.vue')
          },
          {
            path: 'company',
            meta: {
              title: 'Virksomhed'
            },
            name: 'CompanyProfile',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageCompany.vue')
          },
          {
            path: 'subscription',
            meta: {
              title: 'Abonnement'
            },
            name: 'Subscription',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageSubscription.vue')
          },
          {
            path: 'app-settings',
            meta: {
              title: 'App indstillinger'
            },
            name: 'app-settings',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/PageAppSettings.vue')
          }
        ]
      },
      {
        path: '/admin',
        name: 'admin',
        meta: {
          title: 'Admin',
          requiresAuth: true,
          requiresAdmin: true
        },
        component: () => import(/* webpackChunkName: "account" */ '../views/Admin/PageAdmin.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  // @ts-ignore
  const user = store.state.users.user || JSON.parse(sessionStorage.getItem('currentUser'));
  let isAdmin = false;
  if (user && user.isAdmin) {
    isAdmin = true;
  }

  await nextTick();
  const defaultTitle = 'Coachplan';
  document.title = `${defaultTitle}`;
  if (to.meta && to.meta.title) {
    window.document.title = `${to.meta.title} - ${defaultTitle}`;
  } else if (to.params && to.params.pageTitle) {
    window.document.title = `${to.params.pageTitle} - ${defaultTitle}`;
  }

  if (requiresAuth && !user && to.path !== '/') {
    next('/');
    return;
  }

  // Redirect to the dashboard if the user is logged in and lands on the home page
  if (to.path === '/' && user) {
    next('/dashboard');
    return;
  }

  if (requiresAdmin && !isAdmin) {
    next('/not-authorized');
  } else {
    next();
  }
});

export default router;
