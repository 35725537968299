import { Program, ProgramTemplate, Workout } from '@/types/interfaces';

interface State {
  program: Program;
  programTemplate: ProgramTemplate;
}

export const DEFAULT_PROGRAM: Program = {
  title: '',
  categoryId: undefined,
  description: '',
  isActive: false,
  daysAfterCourseStart: 0,
  durationWeeks: 1,
  trainingsPerWeek: 1,
  workouts: [],
  enableExtraDescriptionField: false,
  enableWarmupField: false
};

export default {
  state: (): State => {
    return {
      program: DEFAULT_PROGRAM,
      programTemplate: DEFAULT_PROGRAM
    };
  },
  getters: {
    getProgram(state: State) {
      return state.program;
    },
    getProgramTemplate(state: State) {
      return state.programTemplate;
    },
    programId(state: State) {
      return state.program.id;
    },
    programTemplateId(state: State) {
      return state.programTemplate.id;
    },
    duration(state: State) {
      return state.program.durationWeeks;
    },
    templateDuration(state: State) {
      return state.programTemplate.durationWeeks;
    }
  },
  mutations: {
    setProgram(state: State, program: Program) {
      state.program = program;
    },
    setProgramUsers(state: State, clientIds: string[]) {
      if (!state.program.users) {
        state.program.users = [];
      }
      state.program.users = clientIds;
    },
    setProgramCategory(state: State, category: string | undefined) {
      state.program.categoryId = category;
    },
    removeClientFromProgram(state: State, clientId: string) {
      if (clientId && state.program.users) {
        state.program.users = state.program.users.filter((_clientId) => _clientId !== clientId);
      }
    },
    setWorkoutsToProgram(state: State, workouts: Workout[]) {
      state.program.workouts = workouts;
    },
    setProgramTemplate(state: State, program: ProgramTemplate) {
      state.programTemplate = program;
    },
    setWorkoutsToProgramTemplate(state: State, workouts: Workout[]) {
      state.programTemplate.workouts = workouts;
    },
    resetProgram(state: State) {
      state.program = DEFAULT_PROGRAM;
      state.programTemplate = DEFAULT_PROGRAM;
    }
  },
  actions: {}
};
